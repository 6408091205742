import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/pic04.jpg'

const iframeStyle = {
  margin: '0',
  padding: '0',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  marginBottom: '-0.5em',
}

const mainStyle = {
  overflow: 'hidden',
  padding: '0',
}

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="TBT - Map" />
        <HeaderGeneric />
        <div id="main" style={mainStyle}>
          {/* <section id="content" className="main" > */}
          <iframe
            style={iframeStyle}
            src="http://167.99.183.20/tbay/"
            frameborder="0"
            scrolling="no"
          />

          {/* <h2>Map</h2>
            <p>
              <Link to="/" className="button">
                about this project...
              </Link>
            </p> */}
          {/* </section> */}
        </div>
      </Layout>
    )
  }
}

export default Generic
